import clsx from "clsx";
import Link from "next/link";

type Props = {
  children: React.ReactNode;
  isActive: boolean;
  href: string;
  showOnlyOnMobile?: boolean;
};

export const HeaderLink = ({
  children,
  isActive,
  href,
  showOnlyOnMobile,
}: Props) => {
  return (
    <li
      className={clsx(
        "my-3 flex items-center justify-center px-3 md:my-0 ",
        showOnlyOnMobile && "md:hidden"
      )}
    >
      <Link
        href={href}
        className={clsx(
          " md: flex h-full items-center justify-center overflow-hidden  py-2 text-xl hover:transition-all dark:hover:text-white md:border-b-2 md:text-xs lg:text-sm ",
          isActive &&
            " border-indigo-600 text-indigo-600 hover:text-indigo-600 dark:border-fuchsia-600 dark:text-fuchsia-600 dark:hover:text-fuchsia-600 md:border-b-2",
          !isActive &&
            "border-transparent font-normal text-slate-600 hover:text-black dark:text-gray-300"
        )}
        data-active={isActive}
      >
        {children}
      </Link>
    </li>
  );
};

export default HeaderLink;
