import useTranslation from "next-translate/useTranslation";
import Head from "next/head";
import React from "react";

import { Layout } from "./Layout";

type props = {
  logInLoading?: boolean;
};

export const Loading = ({ logInLoading }: props) => {
  const { t } = useTranslation("common");
  const titleMessage = `${process.env.NEXT_PUBLIC_PRODUCTNAME} - Loading`;

  return (
    <div>
      <Head>
        <title>{titleMessage}</title>
        <meta
          name="description"
          content={process.env.NEXT_PUBLIC_PRODUCTNAME}
        />
      </Head>
      <Layout>
        <div className="text-center">
          <span className="mb-8 text-center font-display text-3xl font-bold text-slate-500 dark:text-fuchsia-600 md:text-6xl">
            {logInLoading ? t("authenticating") : t("loading")}
          </span>
        </div>
      </Layout>
    </div>
  );
};

export default Loading;
