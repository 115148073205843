import clsx from "clsx";
import Link from "next/link";

type Props = {
  children?: React.ReactNode;
  isFakeButton?: Boolean;
  click?: () => void;
  href?: string;
  disabled?: boolean;
  additionalClassNames?: string;
  onPost?: boolean;
  danger?: boolean;
};

export const Button = ({
  children,
  click,
  isFakeButton,
  href,
  disabled,
  additionalClassNames,
  onPost,
  danger = false,
}: Props) => {
  const classes = clsx(
    "relative inline-block font-light font-black  text-white text-sm leading-6 font-medium py-1 px-3  disabled:opacity-60 ",
    danger
      ? "bg-red-600 hover:bg-red-700"
      : "dark:hover:bg-fuchsia-700 dark:disabled:hover:bg-indigo-600  hover:bg-indigo-500 bg-indigo-600",
    onPost ? "text-xs md:text-sm" : "rounded",
    additionalClassNames && `${additionalClassNames}`
  );
  return (
    <>
      {!isFakeButton ? (
        <button onClick={click} className={`${classes}`} disabled={disabled}>
          {children}
        </button>
      ) : href ? (
        <Link href={href} passHref={true} className={classes}>
          {children}
        </Link>
      ) : null}
    </>
  );
};

export default Button;
