import React, { ReactNode } from "react";
import { Header } from "./Header/Header";

type Props = {
  children: ReactNode;
};

export const Layout = ({ children }: Props) => (
  <div>
    <Header />
    <div className="layout mx-auto mt-[100px] max-w-7xl px-2 sm:px-6 lg:px-8">
      {children}
    </div>
  </div>
);

export default Layout;
