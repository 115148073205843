type FetchError = Error & { response?: Response; data: any };

export const fetcher = async <ReturnType>(
  ...args: [RequestInfo, RequestInit?]
): Promise<ReturnType> => {
  if (process.env.NODE_ENV === "development") {
    console.log("Fetcher URL: " + args[0]);
  }
  try {
    const response = await fetch(
      typeof window !== "undefined"
        ? args[0]
        : `${process.env.NEXT_PUBLIC_API_URL}${args[0]}`,
      args[1]
    );
    // if the server replies, there's always some data in json
    // if there's a network error, it will throw at the previous line
    const data = await response.json();

    if (response.ok) {
      return data;
    }
    const error = new Error() as FetchError;
    error.response = response;
    error.data = data;
    throw error;
  } catch (error) {
    if (!(error as FetchError).data) {
      (error as FetchError).data = { message: (error as FetchError).message };
    }
    throw error;
  }
};
