import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";

export const AdminMenu = () => {
  const { t } = useTranslation("navigation");

  const adminMenuLinks = [
    {
      id: 1,
      name: t("New entry"),
      link: "/create",
    },
    {
      id: 2,
      name: t("New connection"),
      link: "/create/connection",
    },
    {
      id: 3,
      name: t("exportData"),
      link: "/admin/export",
    },
    {
      id: 4,
      name: t("exportBenefit"),
      link: "/admin/export-benefit",
    },
    {
      id: 5,
      name: t("userAdmin"),
      link: "/admin/users",
    },
    {
      id: 6,
      name: t("adminSettings"),
      link: "/admin/settings",
    },
  ];
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-4 py-1 text-sm font-medium text-white  hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75  dark:hover:bg-fuchsia-700  dark:disabled:hover:bg-indigo-600">
          <>
            {t("admin")}
            <ChevronDownIcon
              className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
              aria-hidden="true"
            />
          </>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-slate-200 rounded bg-slate-100 text-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:divide-slate-800 dark:bg-slate-700">
          {adminMenuLinks.map((adminMenuLink) => (
            <div key={adminMenuLink.id} className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    href={adminMenuLink.link}
                    className={`${
                      active
                        ? "bg-indigo-600 text-white"
                        : "text-gray-900 dark:text-white"
                    } group flex w-full items-center rounded px-2 py-2 text-sm`}
                  >
                    {adminMenuLink.name}
                  </Link>
                )}
              </Menu.Item>
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default AdminMenu;
