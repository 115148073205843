import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";

export const LanguageSwitch = () => {
  const { asPath } = useRouter();
  const { lang } = useTranslation("common");
  const buttonClassNames =
    "text-sm text-slate-900 dark:text-white px-3 underline";
  return (
    <>
      {lang === "de" ? (
        <Link href={asPath} locale="en">
          <button className={buttonClassNames}>EN</button>
        </Link>
      ) : (
        <Link href={asPath} locale="de">
          <button className={buttonClassNames}>DE</button>
        </Link>
      )}
    </>
  );
};

export default LanguageSwitch;
